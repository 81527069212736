import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import YouTube from "@mui/icons-material/YouTube";
import Twitter from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { Typography } from "@mui/material";
import ".././index.css";
import styled from "@mui/system/styled";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Select } from "@mui/material";
import { US, IN, EU, AE, GB } from "country-flag-icons/react/3x2";
import UserContextCurrency from "../context/currencyChange";

const Icondiv = styled("div")(({ theme }) => ({
  color: theme.palette.cutome.white,
  cursor: "pointer",
  fontSize: "12px",
  transition: "0.3s",
  "&:hover": {
    color: theme.palette.cutome.lightwheat,
  },
}));

const TopNavbar = (props) => {
  const { currencySign, setCurrencySign, areaType, setAreaType } =
    useContext(UserContextCurrency);
  let borderleft = {};
  let borderLeftRight = {};
  if (props.matches) {
    borderleft = {
      borderLeft: "0.5px solid rgba(206, 206, 206, 0.3)",
      p: 1,
    };
    borderLeftRight = {
      borderLeft: "0.5px solid rgba(206, 206, 206, 0.3)",
      borderRight: "0.5px solid rgba(206, 206, 206, 0.3)",
      p: 1,
    };
  } else {
    borderleft = {
      border: "none",
      p: 1,
    };
    borderLeftRight = {
      border: "none",
      p: 1,
    };
  }

  const [currency, setCurrency] = useState("");
  const [area, setArea] = useState("");

  useEffect(() => {
    if (areaType == "SQFT") {
      setArea("SQFT");
    } else if (areaType == "SQM") {
      setArea("SQM");
    }

    if (currencySign == "EUR") {
      setCurrency("EUR");
    } else if (currencySign == "GBP") {
      setCurrency("GBP");
    } else if (currencySign == "USD") {
      setCurrency("USD");
    } else if (currencySign == "INR") {
      setCurrency("INR");
    } else {
      setCurrency("AED");
    }
  }, []);

  const handleCurrency = (event) => {
    let eventPrice = event.target.value;
    setCurrency(eventPrice);
    setCurrencySign(eventPrice);
  };

  const handleArea = (event) => {
    let eventAreaType = event.target.value;
    setArea(eventAreaType);
    setAreaType(eventAreaType);
  };

  return (
    <>
      <Box sx={{ display: { xs: "flex", sm: "flex" } }}>
        <Grid container sx={{ p: 0, m: 0 }}>
          <Grid sm={6} xs={6}>
            <Stack
              direction="row"
              justifyContent={{ xs: "flex-start", sm: "flex-start" }}
            >
              <Box sx={borderleft}>
                <a href="https://www.facebook.com/cityluxedxb/" target="_blank">
                  <Icondiv>
                    <Facebook sx={{ fontSize: "15px", m: "5px" }} />
                  </Icondiv>
                </a>
              </Box>
              <Box sx={borderleft}>
                <a href="https://twitter.com/luxe_city" target="_blank">
                  <Icondiv>
                    <svg
                      style={{ marginTop: "6px" }}
                      className="forHover"
                      viewBox="0 0 24 24"
                      aria-label="Twitter"
                      width={15}
                      role="img"
                      class="your-icon-class"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                          fill="white"
                        ></path>
                      </g>
                    </svg>
                    <Twitter
                      sx={{ fontSize: "15px", m: "5px", display: "none" }}
                    />
                  </Icondiv>
                </a>
              </Box>
              <Box
                sx={{
                  ...borderleft,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                <a
                  href="https://www.linkedin.com/in/cityluxedxb"
                  target="_blank"
                >
                  <Icondiv>
                    <LinkedIn sx={{ fontSize: "15px", m: "5px" }} />
                  </Icondiv>
                </a>
              </Box>
              <Box sx={borderleft}>
                <a
                  href="https://www.youtube.com/channel/UC0L6TDApvFtl_F_bIlVtQNw"
                  target="_blank"
                >
                  <Icondiv>
                    <YouTube sx={{ fontSize: "15px", m: "5px" }} />
                  </Icondiv>
                </a>
              </Box>

              <Box sx={borderLeftRight}>
                <a
                  href="https://www.instagram.com/cityluxe.dxb/"
                  target="_blank"
                >
                  <Icondiv>
                    <Instagram sx={{ fontSize: "15px", m: "5px" }} />
                  </Icondiv>
                </a>
              </Box>
            </Stack>
          </Grid>

          <Grid sm={6} xs={6}>
            <Box
              display="flex"
              justifyContent="flex-center"
              alignItems="center"
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                  <FormControl
                    variant=""
                    size="small"
                    sx={{
                      minWidth: 100,
                      borderLeft: "0.5px solid rgba(206, 206, 206, 0.3)",
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={area}
                      onChange={handleArea}
                      sx={{
                        padding: "0px",
                        border: "none",
                        borderRadius: "0px",
                        color: "#fff",
                        fontSize: "13px",
                        height: "100%",
                      }}
                    >
                      <MenuItem value={"SQFT"} sx={{ fontSize: "15px" }}>
                        <SquareFootOutlinedIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />
                        SQFT
                      </MenuItem>
                      <MenuItem value={"SQM"} sx={{ fontSize: "15px" }}>
                        <SquareFootOutlinedIcon
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />
                        SQM
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                  <FormControl
                    variant=""
                    size="small"
                    sx={{
                      minWidth: 100,
                      borderLeft: "0.5px solid rgba(206, 206, 206, 0.3)",
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={currency}
                      onChange={handleCurrency}
                      sx={{
                        padding: "0px",
                        border: "none",
                        borderRadius: "0px",
                        color: "#fff",
                        fontSize: "13px",
                      }}
                    >
                      <MenuItem value={"AED"} sx={{ fontSize: "15px" }}>
                        <AE
                          title="United Arab Emirates"
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />{" "}
                        AED
                      </MenuItem>
                      <MenuItem value={"EUR"} sx={{ fontSize: "15px" }}>
                        <EU
                          title="European Union "
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />{" "}
                        EUR
                      </MenuItem>
                      <MenuItem value={"GBP"} sx={{ fontSize: "15px" }}>
                        <GB
                          title="United Kingdom"
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />{" "}
                        GBP
                      </MenuItem>
                      <MenuItem value={"USD"} sx={{ fontSize: "15px" }}>
                        <US
                          title="United States"
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />{" "}
                        USD
                      </MenuItem>
                      <MenuItem value={"INR"} sx={{ fontSize: "15px" }}>
                        <IN
                          title="India"
                          style={{
                            width: "22px",
                            height: "22px",
                            paddingRight: "5px",
                          }}
                        />{" "}
                        INR
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Typography
                variant="body2"
                sx={{
                  ...borderleft,
                  display: { lg: "flex", md: "flex", xs: "none", sm: "none" },
                  width: "fit-content",
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  px: "10px",
                  py: "12px",
                  color: "cutome.white",
                }}
              >
                <Icondiv>
                  <a
                    href="https://api.whatsapp.com/send?phone=971554235695&text=Hello, I would like to get more information about this property you posted"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    <WhatsAppIcon sx={{ fontSize: "16px" }} />
                  </a>
                </Icondiv>
                <Box sx={{ pl: "10px" }}>
                  <a
                    href="https://api.whatsapp.com/send?phone=971554235695&text=Hello, I would like to get more information about this property you posted"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    +971 55 423 5695
                  </a>
                </Box>
              </Typography>
              <Typography
                variant="body2"
                color="cutome.white"
                sx={{
                  ...borderLeftRight,
                  display: { lg: "flex", md: "flex", xs: "none", sm: "none" },
                  alignItems: "center",
                  py: "12px",
                  px: "10px",
                }}
              >
                <Icondiv>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@cityluxedxb.com"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    <EmailIcon sx={{ fontSize: "16px" }} />
                  </a>
                </Icondiv>
                <Box sx={{ pl: "10px" }}>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@cityluxedxb.com"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                    }}
                  >
                    sales@cityluxedxb.com
                  </a>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TopNavbar;
