import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { heading } from '../../theme/theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const ImageSlider = ({ projectDetails }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // console.warn("images in slider",projectDetails.image_gallery )
  const images = projectDetails.image_gallery || [];

  return (
    <>
      <Typography sx={{ ...heading }}>Image Gallery</Typography>
      <Box sx={{ display: 'flex', border: "1px solid #d7c3a8" }}>
        <Box sx={{ flex: 1 }}>
          {/* Main image slider */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              height: '50vh',
            }}
          >
            {/* Image container */}
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                flex: '1',
                height: isSmallScreen ? '30vh' : '50vh',
              }}
            >
              {projectDetails.image_gallery.map((imageObj, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    // width: '38vmax',
                    height: index === currentImageIndex ? '100%' : '0',
                    opacity: index === currentImageIndex ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                  }}
                >
                  <img
                    src={imageObj.file_name}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: isSmallScreen ? '98vw' : '-webkit-fill-available',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}
            </Box>

            {/* Image preview container */}
            <Box
              sx={{
                flex: isSmallScreen ? '0 0 auto' : '0 0 15%',
                backgroundColor: '#f0f0f0',
                overflowY: isSmallScreen ? 'hidden' : 'scroll',
                overflowX: isSmallScreen ? 'scroll' : 'hidden',
                display: 'flex',
                flexDirection: isSmallScreen ? 'row' : 'column',
                height: isSmallScreen ? '10vh' : 'auto',
                width: isSmallScreen ? '93vw' : '-webkit-fill-available',
                border: '2px solid #fff',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
                '-ms-overflow-style': 'none',
                scrollbarWidth: 'none',
              }}
            >
              {projectDetails.image_gallery.map((imageObj, index) => (
                <img
                  key={index}
                  src={imageObj.file_name}
                  alt={`Preview ${index + 1}`}
                  style={{
                    width: isSmallScreen ? 'auto' : '124px',
                    height: isSmallScreen ? '80px' : 'auto',
                    margin: isSmallScreen ? '0 4px' : '4px 0',
                    cursor: 'pointer',
                    objectFit: 'cover',
                  }}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  );
};

export default ImageSlider;
