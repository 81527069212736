import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box, Button, Modal, TextField, Snackbar, Alert } from '@mui/material';
import { heading, buttonStyle, subheading } from '../../theme/theme';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: "2px solid #e1d3bc",
  boxShadow: 24,
  p: 3,
};
const CloseIconStyle = {
  position: "absolute",
  top: "5px",
  right: "3px",
  cursor: "pointer",
  '&:hover': {
    color: "red",
    backgroundColor: "#4c4f54"
  }
}
const textfield = {
  backgroundColor: "#ffffff",
  color: "#8593a9",
  borderRadius: "0px",
  border: "0.5px solid #e6e6e6",
  mb: "10px",
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FloorPlans({ projectDetails }) {
  const [value, setValue] = React.useState(0);
  const [floorPlanArray, setFloorPlanArray] = useState('');
  const [projectTitle, setProjectTitle] = useState('');
  useEffect(() => {
    if (projectDetails.floorPlan) {
      setFloorPlanArray(projectDetails.floorPlan);
    }
    setProjectTitle(projectDetails.project.title);
  }, [projectDetails, projectTitle]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (!projectDetails.floorPlan || projectDetails.floorPlan.length === 0) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', mt: "25px", mb: "15px" }}>
      <Typography sx={{ ...heading }}>Floor Plans</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Floor Plans Tabs"
          sx={{ display: "flex", justifyContent: "space-around" }}
        >
          {projectDetails.floorPlan.slice(0, 5).map((item, index) => (
            <Tab
              key={index}
              label={item.type.replace('Floor Plan', '').trim()}
              {...a11yProps(index)}
              sx={{ width: '20%' }}
            />
          ))}
        </Tabs>
      </Box>
      {projectDetails.floorPlan.slice(0, 5).map((item, index) => (
        <CustomTabPanel key={index} value={value} index={index} >
          <iframe title="Embedded PDF Viewer, non-downloadable PDF" src={`${item.plan_url}#toolbar=0`} style={{ width: '100%', height: '55vh' }} alt={`Floor plan ${index + 1}`} ></iframe>
        </CustomTabPanel>
      ))}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ModalFloorPlan floorPlanArray={floorPlanArray} projectTitle={projectTitle} sx={{ ...buttonStyle, display: "flex", ml: "auto", mr: "auto" }} />

      </Box>
    </Box>
  );
};

// modal form for Download Floor Plans

export const ModalFloorPlan = ({ floorPlanArray, projectTitle }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    page_name: "single_project_page",
    agentName: "",
    agentEmail: "sales@cityluxedxb.com ",
    button_type: "download_floorplan_button"
  });
  const handleMobileNumberChange = (value) => {
    let sanitizedValue = value.replace(/\D/g, "");
    if (sanitizedValue && sanitizedValue[0] !== "+") {
      sanitizedValue = `+${sanitizedValue}`;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: sanitizedValue,
    }));
  };
  const sendFormDataToAPI = () => {
    const dataToSend = {
      ...formData,
      page_link: `https://www.cityluxedxb.com/projects/single-project-details/${projectTitle}`
    };

    fetch('/cityluxedxb/contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then(response => {
        if (response.ok) {
          setSnackbarMessage("Thanks For Your Interest! We Will Get Back To You Shortly!");
          setSnackbarOpen(true);
          downloadFloorPlans();

        } else {
          console.error('Failed to send form data');
        }
      })
      .catch(error => {
        console.error('Error sending form data:', error);
      });
  };
  const handleSubmitClick = () => {
    if (!isFormValid()) {
      setSnackbarMessage("Please fill all input fields first.");
      setSnackbarOpen(true);
      return;
    }

    sendFormDataToAPI();
  };
  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.phone
      // formData.message
    );
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClick = () => {
    handleClose();
    handleSubmitClick();
  };
  const downloadFloorPlans = () => {
    floorPlanArray.forEach(item => {
      const link = document.createElement('a');
      link.href = item.plan_url;
      link.download = item.plan_url.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  return (
    <div>
      <Button variant="contained" sx={buttonStyle} onClick={handleOpen}>
        Download Floorplan
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`animate__animated ${open ? 'animate__fadeIn' : 'animate__fadeOut'}`}
      >
        <Box sx={style}>
          <CloseIcon sx={CloseIconStyle} onClick={handleClose} />
          <Typography variant="h5" component="h2" sx={{ ...subheading, display: "flex", justifyContent: "center", mt: 1 }}>
            Download Floorplan
          </Typography>
          <TextField size="small" name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter Name" id='nameTextField' sx={{ ...textfield, width: "100%" }} />
          <TextField size="small" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email" id='emailTextField' sx={{ ...textfield, width: "100%" }} />
          <PhoneInput country={"ae"} value={formData.phone} id="phoneNumberTextField"
            onChange={handleMobileNumberChange}
            countryCodeEditable={false}
            inputProps={{
              name: "phone",
              required: true,
              style: {
                width: "inherit",
                height: "auto",
                borderRadius: "0px",
                border: "1px solid #e3e0e0",
              },
            }}
          />
          <Button variant="contained" color="primary" fullWidth sx={buttonStyle} onClick={handleClick}>
            Download Floorplan
          </Button>
          <Typography variant="body2" sx={{ fontSize: "10px", padding: "2px", marginTop: "10px" }}>
            We value your privacy. Your contact information is never shared with any third party and will remain internal where you can opt out at any time.
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};